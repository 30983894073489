import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export default ({ show, setShow, children }) => {
    const portalEl = typeof window !== 'undefined' ? document.getElementById('portal') : undefined;

    useEffect(() => {
        const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;
        const el = typeof document !== `undefined` ? document.createElement('div') : null;
        portalRoot.appendChild(el);

        return () => {
            portalRoot.removeChild(el);
        }
    }, [])

    const portal = (
        <Backdrop 
            show={show}
            onClick={() => setShow(() => !show)}
        >
            <Modal show={show}>
                <ModalContent>
                    {children}
                </ModalContent>
            </Modal>
        </Backdrop>
    )

    return !portalEl ? null : ReactDOM.createPortal(show ? portal : '', portalEl);
}

const Backdrop = styled.div`
    background-color: rgba(0, 0, 0, .4);
    height: 100vh;
    left: 0px;
    opacity: ${props => props.show ? 1 : 0};
    position: absolute;
    transition: opacity .25s ease-out;
    top: 0px;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    width: 100%;
    z-index: 20;
`

const Modal = styled.div`
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    left: 50%;
    opacity: ${props => props.show ? 1 : 0};
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all .25s ease-out;
    top: ${props => props.show ? '50%' : '60%'};
`

const ModalContent = styled.div`
    display: flex;
    justify-content: center;
`