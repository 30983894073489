import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import styled from 'styled-components';

import Header from "../components/header/index";
import Hero from '../components/Hero';
import { Container, ContentContainer } from "../components/layoutComponents";
import Modal from '../components/modal';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';


const IndexPage = ({ data, location }) => {
  const [ showModal, setShowModal ] = useState(false)
  const sources = [
      {
        ...data.desktopHero.childImageSharp.fluid,
        media: `(min-width: 600px)`
      },
      data.mobileHero.childImageSharp.fluid,
  ]
  return (
    <React.Fragment>
      <SEO title="Home" keywords={keywords} />
      <Header path={location.pathname} />
      <Modal show={showModal} setShow={setShowModal}>
        <StyledIframe 
          title="Darwin Domes Film"
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/OfnebKQ1sus?autoplay=1" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
          allowfullscreen
        >
        </StyledIframe>
      </Modal>
      <Hero sources={sources} setShowModal={setShowModal}>
        <TitleGroup>
          <StyledTitle>Affordable.</StyledTitle>
          <StyledTitle>Alternative.</StyledTitle> 
          <StyledTitle>Housing.</StyledTitle>
        </TitleGroup>
        <StyledButton onClick={() => setShowModal(true)}>
          <StyledButtonText>WATCH THE FILM</StyledButtonText>
        </StyledButton>
      </Hero>
      <Container>
        <ContentContainer>
          <h3 style={{ margin: '-1rem 0 1.5rem' }}>Darwin Domes are an affordable alternative housing idea for people 
          who have always wanted to own a dome.</h3>
          <p>We specialize in DIY products and know-how that empowers the end-user to understand, manufacture, and erect domes from readily accessible tools and materials. The dome pictured above was cutout using the same concepts and step-by-step process layed out in the <Link to="/manual">Darwin Domes Construction Manual</Link>.</p>
        </ContentContainer>
      </Container>
    </React.Fragment>
  )
}

export const data = graphql`
  query {
    desktopHero: file(relativePath: { eq: "hero8_desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          aspectRatio
        }
      }
    }
    mobileHero: file(relativePath: { eq: "hero8_mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, quality: 100) {
          ...GatsbyImageSharpFluid
          aspectRatio
        }
      }
    }
  }
`

const TitleGroup = styled.div`
  h1 {
    padding-right: .75rem;
  }
  h1:last-child {
    padding-right: 0;
  }
`

const StyledTitle = styled.h1`
  color: white;
  display: inline;
  font-weight: 400;
  @media (max-width: 699px) {
    font-size: 2rem;
  }
  @media (max-width: 599px) {
    display: block;
    padding-right: 0;
    text-align: center;
    font-size: 3rem;
  }
`

const StyledButtonText = styled.h5`
    color: #253628;
    margin: .5rem;
    transition: color .25s ease-in-out;
`

const StyledButton = styled.button`
  background-color: #C0CEB2;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
  padding: .5rem;
  transition: all .25s ease-in-out;

  :hover {
    background-color: #8AA989;
    > h5 {
      color: white;
      transition: color .25s ease-out;
    }
  }
`

const StyledIframe = styled.iframe`
    margin-bottom: -10px;
    border: 0;
`

export default IndexPage;
